import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ResultModel } from '../interface/common/result-model.interface';
import { FuelCompany } from '../interface/common/fuel-companies.interface';
import { Country } from '../interface/common/country.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  url = `${environment.BASE_URL}admin/common`;
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  Toast: any = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  loginSet(data: any) {
    localStorage.setItem('efuelToken', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
  }

  logOut() {
    localStorage.removeItem('efuelToken');
  }

  get hasToken() {
    return !!localStorage.getItem('efuelToken')
  }

  async getImage(key: string) {
    key = key.replace('/', '%2F');
    const buffer = await lastValueFrom(this.http.get(`${environment.BASE_URL}images/${key}`, { responseType: 'arraybuffer' }))
    return this.sanitize('data:image/jpg;base64, ' + this._arrayBufferToBase64(buffer)) as string;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  _arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  getFuelCompanies(countryId?: string) {
    let params = new HttpParams();
    if (countryId)
      params = new HttpParams().appendAll({ countryId })
    return this.http.get<ResultModel<FuelCompany[]>>(this.url + '/fuel-companies', { params });
  }

  getCountries() {
    return this.http.get<ResultModel<Country[]>>(this.url + '/countries');
  }


  async openConfirmationDialog(
    text: string = '',
    title: string = 'Are you sure?',
    icon: 'info' | 'warning' | 'error' | 'question' = 'question',
    confirmButtonText: string = "Yes",
    denyButtonText: string = "No",
    showDenyButton: boolean = true,
    showCancelButton: boolean = false
  ) {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonColor: '#000fa0',
      showDenyButton,
      showCancelButton,
      showCloseButton: true,
      confirmButtonText,
      denyButtonText,
    });
  }


}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { CommonService } from './common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private cos: CommonService, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!navigator.onLine) {
      return this.cos.Toast.fire({
        title: 'Please connect to the internet first !',
        icon: 'error'
      });

    }
    const currentUser = localStorage.getItem('efuelToken') || '';
    if (request.responseType !== 'arraybuffer') {
      this.spinner.show();
    }

    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentUser}`
        }
      });
    }

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
    }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log(error);
          errorMsg = error?.error?.message ? `${error.error.message}` : error.message;
        }
        return throwError(() => new Error(errorMsg));
      })
    )
  }
}
